 <template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>权限组列表</a-breadcrumb-item>
            </a-breadcrumb>
            <a-row style="padding: 24px; background: #fff;">
                <a-row justify="start" align="top">
                    <a-col :span="4" style="min-width: 320px;max-width: 320px;">
                        <div class="left_button">
                            <a-button type="primary" @click="addDepartment()">
                                新增部门
                            </a-button>
                            <a-button type="primary" @click="addposition()">
                                新增职位
                            </a-button>
                                                        <a-button type="primary" :loading="loading" @click="start">
                                                            刷新
                                                        </a-button>
                        </div>
                    <span v-for="(item,index) in data" :key=index >
                        <a-menu mode="inline" :open-keys="openKeys" style="min-width: 180px" @openChange="onOpenChange">
                            <a-sub-menu :key='"menu"+item.id'
                                        @titleClick="titleClick(item.id,item.name)">
                                <span slot="title"><a-icon type="mail"/><span>{{item.name}}</span></span>
                                <template v-for="(items,index) in item.children">
                                    <a-menu-item :key=items.id
                                                 @click="handleClick(item.children[index])">
                                         {{items.name}}
                                    </a-menu-item>

                                </template>
                            </a-sub-menu>
                        </a-menu>
                    </span>
                    </a-col>
                    <a-col :span="20" style="padding-left: 10px;width: 1200px;">
                        <a-empty style="margin-top: 10vh" v-show="positioninfoEmpty"/>
                        <a-page-header
                            :ghost="false"
                            :title="editDepartment_name"
                            v-show="departmentEmpty"
                        >
                            <template slot="extra">
                                <a-button :loading="loading" @click="editDepartment">
                                    编辑部门信息
                                </a-button>
                                <a-button :loading="loading" @click="delDepartmentok">
                                    剔除部门
                                </a-button>
                            </template>
                            <a-divider/>
                        </a-page-header>
                        <a-page-header
                            :ghost="false"
                            :title="positiontitle"
                            v-show="Positioninfo"

                        >
                            <template slot="extra">

                                <a-button :loading="loading" @click="editusergroup()">
                                    管理成员
                                </a-button>

                                <a-button :loading="loading" @click="editposition(positiontitle,positionkey)">
                                    编辑职位信息
                                </a-button>

                                <a-button :loading="loading" @click="delPositionok">
                                    剔除职位
                                </a-button>
                                <a-button type="primary" :loading="loading"
                                          @click="editPositionok(positiontitle,positionkey)">
                                    保存权限
                                </a-button>

                                <!--                                <a-button type="primary" :loading="loading" @click="start">-->
                                <!--                                    刷新-->
                                <!--                                </a-button>-->
                            </template>
                            <a-divider/>
                            <a-checkbox-group @change="oncheckboxChange" style="width: 100%" v-model="CheckedListinfo"
                                              :value="Clickinfo">

                                    <a-row type="flex" v-for="(item,index) in Clickinfo" :key="index" justify="start">
<!--                                        <a-col flex="300px" style="margin-right: 20px; min-width: 300px">-->
<!--                                            {{item.label}}-->
<!--&lt;!&ndash;                                            <a-checkbox :value=item.value :checked="checked">&ndash;&gt;-->
<!--&lt;!&ndash;                                                {{item.label}}-{{item.value}}&ndash;&gt;-->
<!--&lt;!&ndash;                                            </a-checkbox>&ndash;&gt;-->
<!--                                        </a-col>-->
                                        <a-col flex="auto" style="min-height: 80px; border-left: 1px #ccc solid;padding-left: 20px;width: 100%;">
                                            <a-row style="padding-top: 10px">
                                                <a-col :span="6" v-for="(items,indexs) in item.structure" :key="indexs"
                                                       style="text-align: left">
                                                    <a-checkbox :value=items.id
                                                                :checked=items.indeterminate>
                                                        {{items.name}}
                                                    </a-checkbox>
                                                </a-col>
                                            </a-row>
                                        </a-col>
                                        <a-divider/>
                                    </a-row>


                            </a-checkbox-group>
                        </a-page-header>


                        <!--                    <a-checkbox-group v-model="CheckedListinfo" :options="Clickinfo" @change="oncheckboxChange" />-->
                    </a-col>

                    <!--                    <a-col :span="20" style="padding-left: 10px;">-->
                    <!--                        <a-checkbox-group v-model="this.Clickinfo" @change="onChange">-->
                    <!--                            <a-row>-->
                    <!--                                <a-col :span="8">-->
                    <!--                                    <a-checkbox value="A">-->
                    <!--                                        A-->
                    <!--                                    </a-checkbox>-->
                    <!--                                </a-col>-->
                    <!--                            </a-row>-->
                    <!--                        </a-checkbox-group>-->
                    <!--                </a-col>-->
                </a-row>
            </a-row>

        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>
        <a-modal title="编辑部门" v-model="popeditDepartment" @ok="editDepartmentok" width="55%">
            <a-row>
                <a-col :span="24">
                    <a-form-item
                        label="名字"
                        :label-col="{ span: 5 }"
                        :wrapper-col="{ span: 12 }"
                        help
                    >
                        <a-input v-model="editDepartment_name"/>
                    </a-form-item>

                    <!--                    <a-form-item-->
                    <!--                        label="备注"-->
                    <!--                        :label-col="{ span: 5 }"-->
                    <!--                        :wrapper-col="{ span: 12 }"-->
                    <!--                        help="备注信息，控制在20字内"-->
                    <!--                    >-->
                    <!--                        <a-textarea placeholder :rows="4" v-model="edit_pop.notes"/>-->
                    <!--                    </a-form-item>-->

                </a-col>
            </a-row>
        </a-modal>
        <!--        <a-modal title="编辑权限组信息" v-model="popinfos" @ok="handleOk" width="55%">-->
        <!--            <a-row>-->
        <!--                <a-col :span="24">-->
        <!--                    <a-form-item-->
        <!--                        label="名字"-->
        <!--                        :label-col="{ span: 5 }"-->
        <!--                        :wrapper-col="{ span: 12 }"-->
        <!--                        help-->
        <!--                    >-->
        <!--                        <a-input v-model="edit_pop.name"/>-->
        <!--                    </a-form-item>-->
        <!--                    <a-form-item label="上级权限组id" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">-->
        <!--                        <a-input v-model="edit_pop.f_id"/>-->
        <!--                    </a-form-item>-->
        <!--                    <a-form-item label=" 管理员权限" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">-->
        <!--                        <a-checkbox-group v-model="perm_permissions_group_info_permissions">-->
        <!--                            <div v-for="(item,index) in perm_data">-->
        <!--                                <a-checkbox :value="item.id" :label="index">-->
        <!--                                    &lt;!&ndash;                                    {{perm_permissions_group_info_permissions == item.id}}&ndash;&gt;-->
        <!--                                    {{item.perm_name}}(id:{{item.id}})-->
        <!--                                </a-checkbox>-->
        <!--                            </div>-->
        <!--                        </a-checkbox-group>-->
        <!--                    </a-form-item>-->
        <!--                    &lt;!&ndash;                    <a-form-item&ndash;&gt;-->
        <!--                    &lt;!&ndash;                        label="备注"&ndash;&gt;-->
        <!--                    &lt;!&ndash;                        :label-col="{ span: 5 }"&ndash;&gt;-->
        <!--                    &lt;!&ndash;                        :wrapper-col="{ span: 12 }"&ndash;&gt;-->
        <!--                    &lt;!&ndash;                        help="备注信息，控制在20字内"&ndash;&gt;-->
        <!--                    &lt;!&ndash;                    >&ndash;&gt;-->
        <!--                    &lt;!&ndash;                        <a-textarea placeholder :rows="4" v-model="edit_pop.notes"/>&ndash;&gt;-->
        <!--                    &lt;!&ndash;                    </a-form-item>&ndash;&gt;-->

        <!--                </a-col>-->
        <!--            </a-row>-->
        <!--        </a-modal>-->
        <a-modal title="新增部门" v-model="popaddDepartment" @ok="addDepartmentok" width="45%">
            <a-form-item label="部门名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="Department_name"/>
            </a-form-item>
        </a-modal>
        <a-modal title="新增职位" v-model="popadd" @ok="addok" width="45%">
            <a-form-item label="职位名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="a_name"/>
            </a-form-item>
            <a-form-item label="所属部门" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-select style="width: 160px" v-model="a_f_permissions_group_id" @change="handleChange">
                    <a-select-option v-for="(item,index) in data" :value="item.id"
                                     :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>


        </a-modal>
        <a-modal title="编辑职位信息" v-model="popeditPosition" @ok="editPositionok(positiontitle,positionkey)" width="45%">
            <a-form-item label="职位名字" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="positiontitle"/>
            </a-form-item>
            <a-form-item label="所属部门" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-select style="width: 160px" v-model="positionfkey" @change="handleChange">
                    <a-select-option v-for="(item,index) in data" :value="item.id"
                                     :key="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>


        </a-modal>
        <a-modal title="添加成员列表" v-model="popaddePermissions" width="75%" :footer="null">
            <a-table
                bordered
                :columns="groupcolumns"
                :dataSource="groupnewlist"
                :pagination="paginationgroupnew"
                :loading="loading"
                rowKey="uid"
            >

                <!--                @change="onChange"-->
                <!--                :row-selection="rowSelection"-->

                <template slot="status" slot-scope="tags" style="text-align: center">
                    <a-tag
                        :color="(tags >1?'red':'green')"
                    >

                        {{['','正常','已剔除'][tags]}}
                    </a-tag>
                </template>
                <template slot="sex" slot-scope="text">


                    <a-tag v-if="text==1" color="green">
                        男
                    </a-tag>
                    <a-tag v-if="text==2" color="cyan">
                        女
                    </a-tag>


                </template>
                <template slot="operation" slot-scope="text, index">
                    <div class="editable-row-operations">
                        <span>
<!--                                v-if="text.status==1"-->
                                <a @click="addPermissionsok(index)">增加权限</a>
                            </span>
                    </div>
                </template>
            </a-table>
        </a-modal>
        <a-modal title="编辑成员列表" v-model="popusergroup" width="75%" :footer="null">
            <a-button type="primary" :loading="loading" @click="addPermissions"
                      style="margin-right: 10px;margin-bottom: 10px">
                添加新成员
            </a-button>
            <a-button type="primary" :loading="loading" @click="allPermissionok"
                      style="margin-right: 10px;margin-bottom: 10px">
                全部剔除
            </a-button>
            <a-table
                bordered
                :columns="groupcolumns"
                :dataSource="grouplist"
                :pagination="paginationgroup"
                :loading="loading"
                @change="onChange"
                :row-selection="rowSelection"
                rowKey="uid"
            >

                <template slot="status" slot-scope="tags" style="text-align: center">
                    <a-tag
                        :color="(tags >1?'red':'green')"
                    >

                        {{['','正常','已剔除'][tags]}}
                    </a-tag>
                </template>
                <template slot="sex" slot-scope="text">


                    <a-tag v-if="text==1" color="green">
                        男
                    </a-tag>
                    <a-tag v-if="text==2" color="cyan">
                        女
                    </a-tag>


                </template>
                <template slot="operation" slot-scope="text, index">
                    <div class="editable-row-operations">
                        <span>
<!--                                v-if="text.status==1"-->
                                <a @click="editPermissionsok(index)">移除权限</a>
                            </span>
                    </div>
                </template>
            </a-table>
        </a-modal>

    </a-layout>
</template>

<script>
    const columns = [
        {
            title: "权限组id",
            dataIndex: "uid",
            width: "5%",
            scopedSlots: {customRender: "uid"}
        },
        {
            title: "权限组名字",
            dataIndex: "name",
            width: "45%",
            scopedSlots: {customRender: "name"}
        },

        {
            title: "状态",
            dataIndex: "status",
            scopedSlots: {customRender: "status"}
        },
        {
            title: "操作",
            width: "10%",
            // key: "operation",
            scopedSlots: {customRender: "operation"}
        }
    ];
    const groupcolumns = [
        {
            title: "用户id",
            dataIndex: "uid",
            width: "15%",
            scopedSlots: {customRender: "uid"}
        },
        {
            title: "登录账号",
            dataIndex: "login_name",
            width: "25%",
            scopedSlots: {customRender: "login_name"}
        },
        {
            title: "用户名字",
            dataIndex: "name",
            width: "15%",
            scopedSlots: {customRender: "name"}
        },
        {
            title: "性别",
            dataIndex: "sex",
            width: "15%",
            scopedSlots: {customRender: "sex"}
        },

        {
            title: "状态",
            dataIndex: "status",
            scopedSlots: {customRender: "status"}
        },
        {
            title: "操作",
            width: "10%",
            // key: "operation",
            scopedSlots: {customRender: "operation"}
        }
    ];

    import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    // import Crypt from "../common/crypt";
    // import * as Util from "../common/util";

    export default {

        name: "Userlist",
        components: {},

        data() {
            return {
                checked: true,
                disabled: false,
                Positioninfo: false,
                popusergroup: false,
                positioninfoEmpty: true,
                departmentEmpty: false,
                rootSubmenuKeys: [],
                openKeys: [],
                newinfo: {},
                Clickinfo: [],
                columns,
                groupcolumns,
                data: [],
                perm_data: [],
                CheckedListinfo: [],
                grouplist: [],
                groupnewlist: [],

                editingKey: '',
                indeterminates: '',
                pagination: {
                    pageSize: 15
                },
                paginationgroup: {
                    pageSize: 15
                },
                paginationgroupnew: {
                    pageSize: 15
                },
                page: 1,
                search_text: "",
                editDepartment_id: "",
                editDepartment_name: "",
                editPosition_id: "",
                editPosition_name: "",

                positiontitle: "",
                positionkey: "",
                positionfkey: "",
                Department_name: "",
                loading: false,
                popeditDepartment: false,
                popaddePermissions: false,
                popeditePermissions: false,
                popeditPosition: false,
                popadd: false,
                popaddDepartment: false,
                edit_pop: {
                    edit_id: "",
                    name: "",
                    permissions: [],
                },
                del_pop: {
                    del_uid: "",
                },
                a_name: "",

                a_permissions: [],
                selectedRowKeys: [],
                Permissionscheck: [],
                a_f_permissions_group_id: "",

                perm_permissions_group_info_permissions: [],
            };
        },
        created() {
            // 获取第一页内容
            this.doSearch("1");
        },
        computed: {
            rowSelection() {
                const {selectedRowKeys} = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: false,
                    // selections: [
                    //     {
                    //         key: 'all-data',
                    //         text: '全选',
                    //         onSelect: () => {
                    //             this.selectedRowKeys = [...Array(46).keys()]; // 0...45
                    //         },
                    //     },
                    //     {
                    //         key: 'odd',
                    //         text: 'Select Odd Row',
                    //         onSelect: changableRowKeys => {
                    //             let newSelectedRowKeys = [];
                    //             newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                    //                 if (index % 2 !== 0) {
                    //                     return false;
                    //                 }
                    //                 return true;
                    //             });
                    //             this.selectedRowKeys = newSelectedRowKeys;
                    //         },
                    //     },
                    //     {
                    //         key: 'even',
                    //         text: 'Select Even Row',
                    //         onSelect: changableRowKeys => {
                    //             let newSelectedRowKeys = [];
                    //             newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                    //                 if (index % 2 !== 0) {
                    //                     return true;
                    //                 }
                    //                 return false;
                    //             });
                    //             this.selectedRowKeys = newSelectedRowKeys;
                    //         },
                    //     },
                    // ],
                    onSelection: this.onSelection,

                    getCheckboxProps: record => ({
                        props: {
                            // 全部默认禁止选中
                            // disabled: false,
                            // 某几项默认禁止选中(R: 当state等于1时)
                            // disabled: record.state == 1,
                            // 某几项默认选中(R: 当state等于1时)
                            disabled: record.status == -1,
                            // defaultChecked: record.status == -1,

                        },
                    }),
                };
            },
        },
        methods: {
            onSelectChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);

                this.selectedRowKeys = selectedRowKeys;
            },
            delPermissions(item) {
                this.Permissionscheck = [];
                console.log(item)
                this.Permissionscheck.push(item.uid)

                this.popeditePermissions = true;

            },
            addPermissions() {
                this.opengroup(1, "", 1, this.positionkey, 2)
                this.popaddePermissions = true;
            },
            async addPermissionsok(item) {
                console.log(item)
                this.Permissionscheck = [];
                this.popaddePermissions = false;
                this.Permissionscheck.push(item.uid)

                let now = Date.now();
                let result = await this.$post("/api/admin/account/changeUserRuleGroup", {
                    uid: Storage.uid,
                    token: Storage.token,
                    rule_group_id: this.positionkey,
                    users: this.Permissionscheck,
                    type: 1,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.grouplist = []
                    await this.opengroup(1, "", 1, this.positionkey, 1)
                    Modal.info({
                        title: "添加权限成功"
                    });
                }
                if (result.status === false) {
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async editPermissionsok(item) {
                console.log(item)
                console.log(this.Permissionscheck)
                this.popeditePermissions = false;
                let now = Date.now();
                let result = await this.$post("/api/admin/account/changeUserRuleGroup", {
                    uid: Storage.uid,
                    token: Storage.token,
                    rule_group_id: this.positionkey,
                    users: this.Permissionscheck,
                    type: 2,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.grouplist = []
                    await this.opengroup(1, "", 1, this.positionkey, 1)
                    Modal.info({
                        title: "移除成功"
                    });
                }
                if (result.status === false) {
                    Modal.info({
                        title: result.msg
                    });
                }
            },

            async allPermissionok() {
                console.log(this.selectedRowKeys)
                if (this.selectedRowKeys == "") {
                    Modal.info({
                        title: "当前选择为空"

                    });
                    return;
                }
                let Permissionscheck = this.selectedRowKeys;
                let now = Date.now();
                let result = await this.$post("/api/admin/account/changeUserRuleGroup", {
                    uid: Storage.uid,
                    token: Storage.token,
                    rule_group_id: this.positionkey,
                    users: Permissionscheck,
                    type: 1,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.grouplist = []
                    this.selectedRowKeys= []
                    await this.opengroup(1, "", 1, this.positionkey, 1)
                    Modal.info({
                        title: "移除成功"
                    });
                }
                if (result.status === false) {
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            start() {
                this.loading = true;
                this.data = [];
                this.Clickinfo = [];
                this.CheckedListinfo = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch("1");
            },
            async onSearch(text) {
                this.data = [];
                this.search_text = text;
                this.doSearch(1, -1, this.search_text);

            },

            async onChange(pagination) {
                await this.doSearch(pagination.current, "1");
            },
            onOpenChange(openKeys) {
                const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
                if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.openKeys = openKeys;
                    console.log("选择openKeys", this.openKeys);
                } else {
                    this.openKeys = latestOpenKey ? [latestOpenKey] : [];
                }
            },
            oncheckboxChange(checkedList) {
                console.log(checkedList)
                this.CheckedListinfo = checkedList;
                this.indeterminate = !!checkedList.length && checkedList.length < this.Clickinfo.length;
                this.checkAll = checkedList.length === this.Clickinfo.length;
            },
            handleChange(value) {
                console.log(`selected ${value}`);
            },
            handleClick(children) {
                console.log('click-e', children);

                this.Positioninfo = true
                this.positioninfoEmpty = false
                this.departmentEmpty = false
                this.positiontitle = children.name;
                this.positionkey = children.id;
                this.positionfkey = children.rule_group_category_id;
                this.selectedRowKeys = [];
                let data = children.structure;
                let infoArray = new Array();
                let infoListArray = new Array();
                //
                for (let i in data) {
                    // let indeterminate = data[i].used.toString()
                    // if ( indeterminate =="1" ){
                    //     this.indeterminates = true
                    // }else{
                    //     this.indeterminates = false
                    // }
                    infoArray[i] = {
                        "label": data[i].name,
                        "value": data[i].id,
                        "structure": data[i].rules,
                        "indeterminate": data[i].used,

                        // "indeterminate": this.indeterminates,
                    }
                    console.log('used-1', data[i].used);
                }
                for (let i in data) {
                    let used = data[i].used
                    if (used == true) {
                        // infoListArray.push(data[i].id)
                        // infoListArray[i] =  {"value":data[i].value.toString()}

                        // "value": data[i].value.toString(),
                        // "structure": data[i].children,
                        // "used": data[i].used,
                        // "indeterminate": this.indeterminates,
                        // }
                    }

                    for (let k in data[i].rules) {
                        console.log('used',data[i].rules[k].used);
                        let used = data[i].rules[k].used

                        if (used == true) {
                            infoListArray.push(data[i].rules[k].id)
                        }
                    }

                }
                this.Clickinfo = infoArray;
                this.CheckedListinfo = infoListArray;
                console.log('Clickinfo', this.Clickinfo);
                // console.log('click', this.openKeys[0]);
                // console.log('infoArray', infoArray);
                // console.log('infoListArray', infoListArray);
                console.log('CheckedListinfo', this.CheckedListinfo);

            },
            // handleClick(e, name, fid) {
            //     console.log('click-e', e);
            //     console.log('click-name', name);
            //     console.log('clic-fid', fid);
            //     console.log('newinfo', this.newinfo);
            //     this.Positioninfo = true
            //     this.positioninfoEmpty = false
            //     this.departmentEmpty = false
            //     this.positiontitle = name;
            //     this.positionkey = e;
            //     this.positionfkey = fid;
            //     this.selectedRowKeys = [];
            //     let data = this.newinfo[e];
            //     let infoArray = new Array();
            //     let infoListArray = new Array();
            //
            //     for (let i in data) {
            //         // let indeterminate = data[i].used.toString()
            //         // if ( indeterminate =="1" ){
            //         //     this.indeterminates = true
            //         // }else{
            //         //     this.indeterminates = false
            //         // }
            //         infoArray[i] = {
            //             "label": data[i].name,
            //             "value": data[i].id,
            //             "structure": data[i].rules,
            //             "indeterminate": data[i].used,
            //
            //             // "indeterminate": this.indeterminates,
            //         }
            //         console.log('used-1', data[i].used);
            //     }
            //     for (let i in data) {
            //         let used = data[i].used
            //         if (used == true) {
            //             infoListArray.push(data[i].id)
            //             // infoListArray[i] =  {"value":data[i].value.toString()}
            //
            //             // "value": data[i].value.toString(),
            //             // "structure": data[i].children,
            //             // "used": data[i].used,
            //             // "indeterminate": this.indeterminates,
            //             // }
            //         }
            //
            //         for (let k in data[i].rules) {
            //             console.log('used', data[i].rules[k].used);
            //             let used = data[i].rules[k].used.toString()
            //
            //             if (used == true) {
            //                 infoListArray.push(data[i].rules[k].id)
            //             }
            //         }
            //
            //     }
            //     this.Clickinfo = infoArray;
            //     this.CheckedListinfo = infoListArray;
            //     console.log('Clickinfo', this.Clickinfo);
            //     // console.log('click', this.openKeys[0]);
            //     // console.log('infoArray', infoArray);
            //     // console.log('infoListArray', infoListArray);
            //     console.log('CheckedListinfo', this.CheckedListinfo);
            //
            // },
            titleClick(id, name) {
                this.editDepartment_id = id
                this.editDepartment_name = name
                this.Positioninfo = false
                this.positioninfoEmpty = false
                this.departmentEmpty = true
            },
            addposition() {
                this.popadd = true;
            },
            editusergroup() {
                //
                this.opengroup(1, "", 1, this.positionkey, 1)
                this.popusergroup = true;
            },
            editposition(name, id) {
                this.editPosition_id = id
                this.editPosition_name = name
                this.popeditPosition = true;
            },

            editDepartment() {
                this.popeditDepartment = true;
            },

            addDepartment() {
                this.popaddDepartment = true;
            },
            async addDepartmentok() {
                this.popaddDepartment = false;
                let data = {
                    name: this.Department_name,
                    is_public: 2,
                };
                console.log(this.a_permissions);
                let now = Date.now();
                let result = await this.$post("/api/admin/ruleGroupCategory/create", {
                    token: Storage.token,
                    uid: Storage.uid,
                    save_value: data,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.data = []
                    await this.start();
                    Modal.info({
                        title: "创建成功"
                    });
                }
                if (result.status === false) {
                    // that.data = []
                    // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async addok() {
                this.popadd = false;
                let data = {
                    name: this.a_name,
                    // permissions: this.a_permissions,
                    rule_group_category_id: this.a_f_permissions_group_id,
                    is_public: 2,
                };
                console.log(this.a_permissions);
                let now = Date.now();
                let result = await this.$post("/api/admin/ruleGroup/create", {
                    token: Storage.token,
                    uid: Storage.uid,
                    save_value: data,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.a_account,
                    //     this.a_password,
                    //     this.a_level,
                    //     this.a_notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });

                if (result.status == true) {
                    this.data = []
                    await this.doSearch(this.page);
                    Modal.info({
                        title: "创建成功"
                    });
                }
                if (result.status === false) {
                    // that.data = []
                    // await this.doSearch(this.page, "1", "", this.estatedefault, "");
                    Modal.info({
                        title: result.msg
                    });
                }
            },
            async editPositionok(name, id) {
                this.popeditDepartment = false;
                this.editPosition_id = id
                this.editPosition_name = name
                console.log(name);
                console.log(this.editPosition_name);
                let data = {
                    name: this.editPosition_name,
                    rules: this.CheckedListinfo,
                    is_public: 2,
                    rule_group_category_id: this.positionfkey,
                };
                let now = Date.now();

                let result = await this.$post("/api/admin/ruleGroup/update", {                // eslint-disable-line no-unused-vars
                    id: this.editPosition_id,
                    token: Storage.token,
                    uid: Storage.uid,
                    save_value: data,
                    // notes: this.edit_pop.notes,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.edit_pop.id,
                    //     JSON.stringify(data),
                    //     this.edit_pop.notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });
                if (result.status == true) {
                    console.log("准备弹窗！");
                    this.data = []
                    await this.start(this.page);
                    Modal.info({
                        title: "成功"
                    });

                } else {
                    await this.start(this.page);
                    Modal.warning({
                        title: "错误信息：" + result.msg
                    });

                }
                // this.start();
            },
            async editDepartmentok() {
                this.popeditDepartment = false;

                let data = {
                    name: this.editDepartment_name,
                    rule_group_category_id: this.editDepartment_id,
                    is_public: 2,
                };
                let now = Date.now();

                let result = await this.$post("/api/admin/ruleGroupCategory/update", {                // eslint-disable-line no-unused-vars
                    id: this.editDepartment_id,
                    token: Storage.token,
                    uid: Storage.uid,
                    save_value: data,
                    // notes: this.edit_pop.notes,
                    t: now,
                    // sign: Crypt.sign([
                    //     Storage.token,
                    //     this.edit_pop.id,
                    //     JSON.stringify(data),
                    //     this.edit_pop.notes,
                    //     this.$config.secret_key,
                    //     now
                    // ])
                });
                if (result.status == true) {
                    console.log("准备弹窗！");
                    this.data = []
                    await this.start(this.page);
                    Modal.info({
                        title: "成功"
                    });

                } else {
                    await this.start(this.page);
                    Modal.warning({
                        title: "错误信息：" + result.msg
                    });

                }
                // this.start();
            },

            async delDepartmentok() {
                var that = this;

                let result = []; // eslint-disable-line no-unused-vars
                Modal.warning({
                    title: "警告！",
                    content: "是否确认移除该成员",
                    onOk: async function () {
                        console.log("执行完毕！");
                        let result = await that.$post("/api/admin/ruleGroupCategory/delete", {
                            id: that.editDepartment_id,
                            token: Storage.token,
                            uid: Storage.uid,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(that.page);
                            Modal.info({
                                title: "移除成功"
                            });
                        } else {
                            Modal.warning({
                                title: "错误信息：" + result.msg
                            });
                        }

                    }

                });


            },
            // async editPositionok() {
            //     this.popeditDepartment = false;
            //
            //     let data = {
            //         name: this.editDepartment_name,
            //     };
            //     let now = Date.now();
            //
            //     let result = await this.$post("/api/admin/permissionsGroupType/edit", {                // eslint-disable-line no-unused-vars
            //         id: this.editDepartment_id,
            //         token: Storage.token,
            //         uid: Storage.uid,
            //         save_value: data,
            //         // notes: this.edit_pop.notes,
            //         t: now,
            //         // sign: Crypt.sign([
            //         //     Storage.token,
            //         //     this.edit_pop.id,
            //         //     JSON.stringify(data),
            //         //     this.edit_pop.notes,
            //         //     this.$config.secret_key,
            //         //     now
            //         // ])
            //     });
            //     if (result.status == true) {
            //         console.log("准备弹窗！");
            //         this.data = []
            //         await this.start(this.page);
            //         Modal.info({
            //             title: "成功"
            //         });
            //
            //     } else {
            //         await this.start(this.page);
            //         Modal.warning({
            //             title: "错误信息：" + result.msg
            //         });
            //
            //     }
            //     // this.start();
            // },

            async delPositionok() {
                var that = this;

                let result = []; // eslint-disable-line no-unused-vars
                Modal.warning({
                    title: "警告！",
                    content: "是否确认剔除该部门",
                    onOk: async function () {
                        console.log("执行完毕！");
                        let result = await that.$post("/api/admin/ruleGroup/delete", {
                            id: that.positionkey,
                            token: Storage.token,
                            uid: Storage.uid,
                            // notes: this.edit_pop.notes,
                            t: Date.now(),
                            // sign: Crypt.sign([
                            //     Storage.token,
                            //     this.edit_pop.id,
                            //     JSON.stringify(data),
                            //     this.edit_pop.notes,
                            //     this.$config.secret_key,
                            //     now
                            // ])
                        });
                        console.log(result);
                        if (result.status == true) {
                            console.log("准备弹窗！");
                            that.data = []
                            await that.doSearch(that.page);
                            Modal.info({
                                title: "成功"
                            });
                        } else {
                            Modal.warning({
                                title: "错误信息：" + result.msg
                            });
                        }

                    }

                });


            },


            async doSearch(page) {
                this.page = page;
                this.loading = true;
                let limit = 15;
                let pages = page;
                this.rootSubmenuKeys = [];
                this.openKeys = [];

                // console.log(userstatus);
                let uid = Storage.uid;
                let now = Date.now();
                let token = Storage.token;
                let result = await this.$get("/api/admin/ruleGroupCategory/list", {
                    params: {
                        uid: uid,
                        token: token,
                        limit: limit,
                        page: pages,

                        // company_id: Storage.company_id,
                        // rule_group_category_id:rule_group_category_id,
                        t: now,
                    },
                    // sign: Crypt.sign([
                    //     uid,
                    //     token,
                    //     pages,
                    //     limit,
                    //     // this.$config.secret_key,
                    // ])
                });
                console.log(result)
                if (result.status === true) {
                    let data = [];
                    for (let i in result.data) {
                        let info = result.data[i];
                        let offset = (parseInt(page) - 1) * limit + parseInt(i);
                        this.rootSubmenuKeys[i] = "menu" + result.data[i].id;

                        data[offset] = {
                            id: info.id,
                            company_id: info.company_id,
                            name: info.name,
                            is_public: info.is_public,
                            children: info.children,
                        };
                        console.log(data)
                        for (let k in result.data[i]["children"]) {
                            this.newinfo[data[i]["children"][k]["rule_group_category_id"]] = data[i]["children"][k];
                            // this.newinfo[data[i]["children"][k]["rule_group_category_id"]] = data[i]["children"][k]["structure"];

                            // this.newinfo[data[i]["children"][k]["structure"]] = data[i]["children"][k]["structure"];
                        }

                        if (i == 0) {
                            console.log("result", result)
                            this.openKeys[i] = "menu" + result.data[i].id;
                        }
                    }

                    this.data = data;
                    console.log(this.rootSubmenuKeys)
                    console.log("openKeys", this.openKeys)
                    console.log("newinfo", this.newinfo)
                    console.log(result)
                    // let pagination = {...this.pagination};
                    // pagination.total = result.data.total;
                    // this.pagination = pagination;

                    this.loading = false;
                }

                this.loading = false;
            },
            async opengroup(page, search, status, rule_group_category_id, in_group) {
                this.page = page;
                this.loading = true;

                let limit = 15;
                let pages = page;
                let searchs = search;
                console.log(in_group);
                let uid = Storage.uid;
                let now = Date.now();
                let token = Storage.token;
                if (in_group == 1) {
                    this.grouplist = [];
                    let result = await this.$get("/api/admin/account/list", {
                        params: {
                            uid: uid,
                            token: token,
                            limit: limit,
                            page: pages,
                            status: status,
                            search: searchs,
                            rule_group_id: rule_group_category_id,
                            type: in_group,
                            user_type: 2,
                            // company_id: Storage.company_id,
                            // company_id: Storage.company_id,
                            // rule_group_category_id:rule_group_category_id,
                            t: now,
                        },
                        // sign: Crypt.sign([
                        //     uid,
                        //     token,
                        //     pages,
                        //     limit,
                        //     // this.$config.secret_key,
                        // ])
                    });
                    if (result.status === true) {
                        let data = [];
                        for (let i in result.data.list) {
                            let info = result.data.list[i];
                            let offset = (parseInt(page) - 1) * limit + parseInt(i);

                            data[offset] = {
                                uid: info.uid,
                                name: info.name,
                                group_name: info.group_name,
                                login_name: info.login_name,
                                mobile: info.phone,
                                email: info.email,
                                status: info.status,
                                sex: info.sex,
                                rule_group_category_id: info.rule_group_category_id,
                                // company_name: info.company_name,
                                // company_id: info.company_id,
                                // username: info.account,
                                // money: info.amount / this.$config.precision,
                                // upimg: info.prove,
                                // address: info.address,
                                // admin: info.admin_id,
                                // status: info.status,
                                // times: Util.formatTime(info.at_time)
                            };
                        }

                        this.grouplist = data;

                        let pagination = {...this.paginationgroup};
                        pagination.total = result.data.total;
                        this.paginationgroup = pagination;

                        this.loading = false;
                    }
                }
                if (in_group == 2) {
                    this.groupnewlist = [];
                    let result = await this.$get("/api/admin/account/list", {
                        params: {
                            uid: uid,
                            token: token,
                            limit: limit,
                            page: pages,
                            status: status,
                            search: searchs,
                            rule_group_id: rule_group_category_id,
                            type: in_group,
                            user_type: 2,
                            // company_id: Storage.company_id,
                            // company_id: Storage.company_id,
                            // rule_group_category_id:rule_group_category_id,
                            t: now,
                        },
                        // sign: Crypt.sign([
                        //     uid,
                        //     token,
                        //     pages,
                        //     limit,
                        //     // this.$config.secret_key,
                        // ])
                    });
                    if (result.status === true) {
                        let data = [];
                        for (let i in result.data.list) {
                            let info = result.data.list[i];
                            let offset = (parseInt(page) - 1) * limit + parseInt(i);

                            data[offset] = {
                                uid: info.uid,
                                name: info.name,
                                group_name: info.group_name,
                                login_name: info.login_name,
                                mobile: info.phone,
                                email: info.email,
                                status: info.status,
                                sex: info.sex,
                                rule_group_category_id: info.rule_group_category_id,
                                // company_name: info.company_name,
                                // company_id: info.company_id,
                                // username: info.account,
                                // money: info.amount / this.$config.precision,
                                // upimg: info.prove,
                                // address: info.address,
                                // admin: info.admin_id,
                                // status: info.status,
                                // times: Util.formatTime(info.at_time)
                            };
                        }

                        this.groupnewlist = data;

                        let pagination = {...this.paginationgroupnew};
                        pagination.total = result.data.total;
                        this.paginationgroupnew = pagination;

                        this.loading = false;
                    }
                }
                this.loading = false;
            },


        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .imgs {
        text-align: center;
        display: block;
    }

    .imgs img {
        height: 200px;
        width: auto;
    }

    .left_button {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        margin-left: 0px;
        z-index: 1000;
    }

    .left_button button {
        margin-right: 5px;
    }

    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
        z-index: 1000;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff;
    }

    .ant-checkbox-group /deep/ .ant-checkbox-wrapper:first-child {
        margin-left: 8px
    }
</style>
